import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import SidePanel from '../../layouts/Sidepanel'
import '../../styles/pages/admin/settings.scss'

const FeatureControl = () => {
  const [features, setFeatures] = useState([
    { id: 1, name: 'Sell', enabled: true },
    { id: 2, name: 'Service', enabled: true },
    { id: 3, name: 'Buy', enabled: true },
  ])

  const handleToggle = id => {
    setFeatures(
      features.map(feature =>
        feature.id === id ? { ...feature, enabled: !feature.enabled } : feature
      )
    )
  }

  return (
    <div className="feature-control-content">
      <h2>Feature Control</h2>
      <div className="features-list">
        {features.map(feature => (
          <div key={feature.id} className="feature-item">
            <span className="feature-name">{feature.name}</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={feature.enabled}
                onChange={() => handleToggle(feature.id)}
              />
              <span className="slider"></span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

const PrivacyConfig = () => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'script',
    'indent',
    'direction',
    'color',
    'background',
    'font',
    'align',
    'link',
    'image',
    'video',
  ]

  return (
    <div className="privacy-config-content">
      <h2>Privacy Configuration</h2>
      <div className="editor-container">
        <div className="title-input">
          <input
            type="text"
            placeholder="Enter title..."
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          modules={modules}
          formats={formats}
          placeholder="Start typing your content here..."
        />
      </div>
    </div>
  )
}

const PromosDiscount = () => {
  return (
    <div className="promos-discount-content">
      <h2>Promos and Discounts</h2>
    </div>
  )
}

const Settings = () => {
  const [active, setActive] = useState('feature-control')

  const renderActiveComponent = () => {
    switch (active) {
      case 'feature-control':
        return <FeatureControl />
      case 'privacy-config':
        return <PrivacyConfig />
      case 'promos-discount':
        return <PromosDiscount />
      default:
        return <FeatureControl />
    }
  }

  return (
    <div className="dashboard">
      <div className="panel-wrapper">
        <SidePanel />
        <div className="container">
          <div className="container-wrapper">
            <div className="country">
              <div
                style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                Active Country:{' '}
                <span className="" style={{ fontWeight: 700 }}>
                  Philippines
                </span>{' '}
              </div>
            </div>
            <div className="control-container">
              <div className="settings-nav">
                <div className="nav-section">
                  <ul>
                    <li
                      className={active === 'feature-control' ? 'active' : ''}
                      onClick={() => setActive('feature-control')}>
                      <a>Feature Control</a>
                    </li>
                    <li
                      className={active === 'privacy-config' ? 'active' : ''}
                      onClick={() => setActive('privacy-config')}>
                      <a>Privacy Config</a>
                    </li>
                    <li
                      className={active === 'promos-discount' ? 'active' : ''}
                      onClick={() => setActive('promos-discount')}>
                      <a>Promos/Discount</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="settings-content">{renderActiveComponent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
