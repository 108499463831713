import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Icons from '../../assets/icons'
import placeHolderImg from '../../assets/images/item-placeholder.png'
import noPost from '../../assets/images/no-post.png'
import Spinner from '../../components/Spinner'
import Modal from '../../layouts/Modal'
import SidePanel from '../../layouts/Sidepanel'
import Api from '../../services/Api'

const archiveReasons = [
  'Post is Inactive',
  'Violation of Community Guidelines',
  'Inappropriate Content',
  'Spam or Misleading Information',
  'Copyright Infringement',
  'User Requested Archival',
  'Duplicate Content',
]

const deleteUserReason = [
  'Did not comply with community standards',
  'Multiple Violations of Community Guidelines',
  'Persistent Inappropriate Behavior',
  'Spamming Across Multiple Posts',
  'Fake or Impersonated Account',
  'Serious Legal Violations',
  'Violation of Terms of Service',
  'User Requested Deletion',
]

const suspendReasons = [
  'Contains Illegal Products/Services',
  'Contains Hate Speech',
  'Harassment or Bullying',
  'False Information',
  'Impersonation',
  'Graphic or Violent Content',
  'Copyright Violation',
  'Suspicious Activity',
]

const ContentModerator = () => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('')
  const [items, setItems] = useState([])
  const [keywords, setKeywords] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [modalType, setModalType] = useState('')
  const [postId, setPostId] = useState('')
  const [uid, setUid] = useState('')
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null)
  const [automatedSearchResults, setAutomatedSearchResults] = useState([])
  const [selectedReason, setSelectedReason] = useState('')
  const [message, setMessage] = useState('')
  const [isAutoSearch, setIsAutoSearch] = useState(true)
  const [hasPendingTransaction, setHasPendingTransaction] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [deleteMessageRes, setDeleteMessageRes] = useState('')
  const [isDeleted, setIsDeleted] = useState(false)

  useEffect(() => {
    // Set initial reason based on modal type
    if (modalType === 'archive-post') {
      setSelectedReason(archiveReasons[0])
    } else if (modalType === 'delete-user') {
      setSelectedReason(deleteUserReason[0])
    } else if (modalType === 'suspend-post') {
      setSelectedReason(suspendReasons[0])
    }
  }, [modalType])

  const dropdownRefs = useRef([])

  const searchPost = useCallback(async searchTerm => {
    if (!searchTerm?.trim()) return []

    try {
      setIsLoading(true)
      const response = await Api.searchPosts({
        search: searchTerm,
        sort: 'price_low_to_high',
      })

      const sortedPosts = response.data.sort((a, b) => {
        if ((a.archived && !b.archived) || (a.suspended && !b.suspended))
          return 1
        if ((!a.archived && b.archived) || (!a.suspended && b.suspended))
          return -1
        return 0
      })

      return sortedPosts
    } catch (error) {
      console.error('Error fetching posts:', error)
      return []
    } finally {
      setIsLoading(false)
    }
  }, [])

  const performAutomatedSearch = useCallback(
    async flagKeys => {
      try {
        setIsLoading(true)
        const searchResults = await Promise.all(
          flagKeys.map(async keyword => ({
            keyword,
            items: await searchPost(keyword),
          }))
        )
        setAutomatedSearchResults(searchResults)
      } catch (error) {
        console.error('Error in automated search:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [searchPost]
  )

  useEffect(() => {
    console.log(automatedSearchResults)
  }, [automatedSearchResults])

  const getFlagKeys = useCallback(async () => {
    try {
      const response = await Api.getFlagKeys()
      if (!response.success) {
        return
      }

      const flagKeys = response.data.flagKeys
      setKeywords(flagKeys)
      await performAutomatedSearch(flagKeys)
    } catch (error) {
      console.error(error)
    }
  }, [performAutomatedSearch])

  const handleInputChange = useCallback(event => {
    setSearchValue(event.target.value)
    if (!isLoading) {
      setIsAutoSearch(false)
    }
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 500)

    return () => clearTimeout(handler)
  }, [searchValue])

  const formatTimestamp = useCallback((timestamp, type = 'date') => {
    if (!timestamp?.hasOwnProperty('_seconds')) {
      throw new Error('Invalid timestamp object')
    }

    const date = new Date(timestamp._seconds * 1000)

    if (type === 'date') {
      return date.toISOString().split('T')[0]
    }

    return date.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
  }, [])

  const handlePostAction = useCallback(
    async (action, id) => {
      try {
        setIsLoading(true)
        let response

        switch (action) {
          case 'archive':
            response = await Api.archivePost({ pid: id })
            setModalType('success-archive')
            break
          case 'unarchive':
            response = await Api.unarchivePost({ pid: id })
            setModalType('success-unarchive')
            break
          case 'suspend':
            response = await Api.suspendPost({ pid: id })
            setModalType('success-suspend')
            break
          case 'unsuspend':
            response = await Api.unsuspendPost({ pid: id })
            setModalType('success-unsuspend')
            break
          default:
            throw new Error('Invalid action')
        }

        if (!response.success) throw new Error(response.message)

        setOpenDropdownIndex(null)
        if (['archive', 'suspend', 'unarchive', 'unsuspend'].includes(action)) {
          setPostId('')
        }

        // Refresh Lists
        if (debouncedSearchValue) {
          const updatedItems = await searchPost(debouncedSearchValue)
          setItems(updatedItems)
        }

        // Refresh automated search results
        await performAutomatedSearch(keywords)
      } catch (error) {
        console.error(`Error ${action}ing post:`, error)
      } finally {
        setIsLoading(false)
      }
    },
    [searchPost, debouncedSearchValue, performAutomatedSearch, keywords]
  )

  const handleDeleteUser = async id => {
    setModalLoading(true)
    try {
      const response = await Api.deleteAccountContentModeration({ uid: id })
      setModalLoading(false)

      if (!response.success) {
        setHasPendingTransaction(response.hasPendingTransactions)
        setDeleteMessageRes(response.message)
      } else {
        setIsDeleted(true)
      }
    } catch (error) {
      setModalLoading(false)
      console.error('Error deleting user:', error)
    }
  }

  const toggleDropdown = useCallback(
    (event, index) => {
      event.stopPropagation()
      setOpenDropdownIndex(openDropdownIndex === index ? null : index)
    },
    [openDropdownIndex]
  )

  useEffect(() => {
    const handleClickOutside = event => {
      if (event.target.tagName === 'BUTTON' || event.target.closest('button')) {
        return
      }

      if (dropdownRefs.current.every(ref => !ref?.contains(event.target))) {
        setOpenDropdownIndex(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    if (debouncedSearchValue) {
      searchPost(debouncedSearchValue).then(setItems)
    }
  }, [debouncedSearchValue, searchPost])

  useEffect(() => {
    getFlagKeys()
  }, [getFlagKeys])

  const generateRandomStrings = (length = 8) =>
    Array.from({ length: 4 }, () =>
      Array.from({ length }, () =>
        String.fromCharCode(
          [48, 65, 97][Math.floor(Math.random() * 3)] +
            Math.floor(Math.random() * 26)
        )
      ).join('')
    )

  const renderPost = useCallback(
    (post, index) => {
      let customIndex = `${index}-${post.id}`
      return (
        <div key={customIndex} className="post-card">
          <div className="post-info">
            <div className="post-image">
              <img
                src={
                  post.cover_photos.length
                    ? `${Api.domain}${post.cover_photos[0]}`
                    : placeHolderImg
                }
                alt=""
              />
            </div>
            <div className="post-details">
              <div className="post-title">
                <span
                  dangerouslySetInnerHTML={{
                    __html: post._highlightResult.title.value,
                  }}></span>
                <span className="verified">✓</span>
              </div>
              <p
                className="post-description"
                dangerouslySetInnerHTML={{
                  __html: post._highlightResult.description.value,
                }}></p>
            </div>
          </div>
          <div className="author-info">
            <div className="author-details">
              <div className="author-name">
                <span>{post.user.full_name}</span>
              </div>
              <div className="author-handle">{post.user.display_name}</div>
            </div>
          </div>
          <div className="post-date">
            <div className="post-date-main">
              {formatTimestamp(post.date_posted, 'date')}
            </div>
            <div className="post-date-sub">
              {formatTimestamp(post.date_posted, 'time')}
            </div>
          </div>
          <div className="actions">
            {post.archived ? (
              <div className="is-archived">Post has been archived</div>
            ) : post.suspended ? (
              <div className="is-suspended">Post has been suspended</div>
            ) : (
              <>
                <button
                  className="archive-btn"
                  onClick={() => {
                    setModalType('archive-post')
                    setConfirm(true)
                    setPostId(post.id)
                    setOpenDropdownIndex(null)
                  }}>
                  <Icons.Archive className="archive-icon" /> Archive
                </button>
                <button
                  className="warning-btn"
                  onClick={() => {
                    setModalType('suspend-post')
                    setConfirm(true)
                    setPostId(post.id)
                    setOpenDropdownIndex(null)
                  }}>
                  <Icons.Warning className="warning-icon" /> Suspend
                </button>
              </>
            )}
            <div
              className="menu-action dropdown-container"
              ref={el => (dropdownRefs.current[index] = el)}>
              <Icons.MenuAction onClick={e => toggleDropdown(e, customIndex)} />
              {openDropdownIndex === customIndex && (
                <div className="dropdown-menu">
                  <div className="title">More Actions</div>
                  {post.archived && (
                    <button
                      className="unarchive"
                      onClick={() => handlePostAction('unarchive', post.id)}>
                      Unarchive Post
                    </button>
                  )}
                  {post.suspended && (
                    <button
                      className="unsuspend"
                      onClick={() => handlePostAction('unsuspend', post.id)}>
                      Unsuspend Post
                    </button>
                  )}
                  <button
                    className="delete-user"
                    onClick={() => {
                      setModalType('delete-user')
                      setConfirm(true)
                      setOpenDropdownIndex(null)
                      setUid(post.uid)
                    }}>
                    Delete User
                  </button>
                  <button onClick={() => setOpenDropdownIndex(null)}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    },
    [formatTimestamp, handlePostAction, toggleDropdown]
  )

  const renderModalContent = () => {
    const modalConfig = {
      'delete-user': {
        title: 'Delete User',
        subtitle:
          'This will permanently delete the user. Are you sure to delete this user? To proceed, kindly provide a reason.',
        options: deleteUserReason,
        action: () => handleDeleteUser(uid),
        buttonText: 'Delete User',
        buttonClass: 'confirm',
      },
      'archive-post': {
        title: 'Archive Post',
        subtitle: 'To archive this post, kindly provide a reason.',
        options: archiveReasons,
        action: () => handlePostAction('archive', postId),
        buttonText: 'Archive Post',
        buttonClass: 'archive',
      },
      'suspend-post': {
        title: 'Suspend Post',
        subtitle: 'To suspend this post, kindly provide a reason.',
        options: suspendReasons,
        action: () => handlePostAction('suspend', postId),
        buttonText: 'Suspend Post',
        buttonClass: 'suspend',
      },
      'success-archive': {
        title: 'Success',
        subtitle: 'Post has been successfully archived',
        buttonText: 'Close',
        action: () => setConfirm(false),
        buttonClass: 'success',
      },
      'success-suspend': {
        title: 'Success',
        subtitle: 'Post has been successfully suspended',
        buttonText: 'Close',
        action: () => setConfirm(false),
        buttonClass: 'success',
      },
    }

    const config = modalConfig[modalType]
    if (!config) return null

    return (
      <>
        <div className="title">{config.title}</div>
        {!hasPendingTransaction && (
          <div className="sub-title">{config.subtitle}</div>
        )}
        {!['success-archive', 'success-suspend'].includes(modalType) ? (
          <>
            {modalLoading ? (
              <div className="spinner">
                <Spinner type="Oval" color="#ffd400" height={40} width={40} />
              </div>
            ) : (
              <>
                {hasPendingTransaction ? (
                  <div
                    className="sub-title"
                    style={{
                      minWidth: '30rem',
                      textAlign: 'center',
                      padding: '3rem',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                    <Icons.Error />
                    {deleteMessageRes}
                  </div>
                ) : (
                  <>
                    {isDeleted ? (
                      <>
                        <div className="icon-center">
                          <Icons.Success />
                        </div>
                        <div
                          style={{ textAlign: 'center', marginBottom: '1rem' }}>
                          User successfully Deleted
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="select-wrap">
                          <label>Select Reason</label>
                          <select
                            onChange={e => setSelectedReason(e.target.value)}>
                            {config.options?.map((reason, index) => (
                              <option key={index} value={reason}>
                                {reason}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="message-container">
                          <textarea
                            className="message"
                            placeholder="Your message (Optional)"
                            onChange={e =>
                              setMessage(e.target.value)
                            }></textarea>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className="icon-center">
              <Icons.Success />
            </div>
            <div className="success-wrap">
              <label>Reason</label>
              <div>{selectedReason || 'None'}</div>
            </div>
            <div className="message-reason">
              <label>Message</label>
              <div>{message || 'None'}</div>
            </div>
          </>
        )}
        <div className="modal-action">
          {!hasPendingTransaction && (
            <button className={config.buttonClass} onClick={config.action}>
              {config.buttonText}
            </button>
          )}
          {!['success-archive', 'success-suspend'].includes(modalType) && (
            <button
              onClick={() => {
                setConfirm(false)
                setHasPendingTransaction(false)
                setIsDeleted(false)
              }}>
              Cancel
            </button>
          )}
        </div>
      </>
    )
  }

  return (
    <div className="content-moderator">
      <SidePanel />
      <div className="content">
        <div className="datatable-wrapper">
          <div className="datatable-header">
            <div className="datatable-header-label-wrapper">
              <span className="datatable-header-label">Content Moderation</span>
            </div>
            <div className="search-flag-wrapper">
              <div className="manage-flag">
                <Link to="/admin/content-moderator/flag-keys">
                  Manage Flag Keys
                </Link>
              </div>
              <div className="search-wrapper">
                <Icons.Search className="search-icon" />
                <input
                  type="search"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          {!isAutoSearch ? (
            <>
              {items.length > 0 ? (
                <>
                  <div className="posts-header">
                    <div>Post</div>
                    <div>Posted by</div>
                    <div>Date Posted</div>
                    <div className="posts-header-actions">Actions</div>
                  </div>
                  {isLoading ? (
                    <div className="spinner">
                      <Spinner
                        type="Oval"
                        color="#ffd400"
                        height={40}
                        width={40}
                      />
                    </div>
                  ) : items.length ? (
                    <div className="posts-list">
                      {items.map((post, index) => renderPost(post, index))}
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <div className="posts-header">
                    <div>Post</div>
                    <div>Posted by</div>
                    <div>Date Posted</div>
                    <div className="posts-header-actions">Actions</div>
                  </div>
                  <div className="no-post">
                    <img src={noPost} alt="" />
                    <div className="flag-title">No flagged posts available</div>
                    <div className="flag-desc">
                      Search post/s you need to manage. You also can add
                      keywords here to automatically flag posts that may contain
                      illegal products and services.
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {automatedSearchResults.length > 0 ? (
                <>
                  {automatedSearchResults.map((result, index) => (
                    <div key={index} className="flag-container">
                      <div className="flag-title">
                        ({result.items.length}) Flagged content with "
                        {result.keyword}" mentioned in their post(s)
                      </div>
                      <div className="posts-header">
                        <div>Post</div>
                        <div>Posted by</div>
                        <div>Date Posted</div>
                        <div className="posts-header-actions">Actions</div>
                      </div>
                      <div className="posts-list">
                        {result.items.map((post, index) =>
                          renderPost(post, index)
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="posts-header">
                    <div>Post</div>
                    <div>Posted by</div>
                    <div>Date Posted</div>
                    <div className="posts-header-actions">Actions</div>
                  </div>
                  <div className="no-post">
                    <img src={noPost} alt="" />
                    <div className="flag-title">No flagged posts available</div>
                    <div className="flag-desc">
                      Search post/s you need to manage. You also can add
                      keywords here to automatically flag posts that may contain
                      illegal products and services.
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {confirm && (
          <Modal setViewModal={setConfirm} modalClass="confirmation-modal">
            {renderModalContent()}
          </Modal>
        )}
      </div>
    </div>
  )
}

export default ContentModerator
