import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import Api from '../../services/Api'

import { format } from 'date-fns'
import { useDebouncedCallback } from 'use-debounce'
import Icons from '../../assets/icons'
import Sidepanel from '../../layouts/Sidepanel'

const Transactions = () => {
  const navigate = useNavigate()
  const [transactions, setTransactions] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
    sort: 'date_desc',
  })

  const columns = React.useMemo(
    () => [
      {
        name: 'Order ID',
        selector: 'order_id',
        width: '200px',
      },
      {
        name: 'Seller Name',
        selector: 'seller_name',
      },
      {
        name: 'Buyer Name',
        selector: 'buyer_name',
      },
      {
        name: 'Post Type',
        selector: 'post_type',
        width: '75px',
      },
      {
        name: 'Order Total',
        selector: 'order_total',
        cell: row =>
          `₱${row.order_total.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })}`,
      },
      {
        name: 'Order status',
        selector: 'order_status',
        sortable: true,
      },
      {
        name: 'Method',
        selector: 'payment_method',
      },
      {
        name: 'Payment ID',
        selector: 'payment_id',
        cell: row => (row.payment_id ? row.payment_id : <span>N/A</span>),
      },
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
        cell: row =>
          row.date ? (
            <span>
              {format(row.date?._seconds * 1000, 'MMM dd, yyyy hh:mm:ii')}
            </span>
          ) : (
            <span>N/A</span>
          ),
        width: '180px',
      },
    ],
    []
  )

  const handleOnSearchChange = useDebouncedCallback(value => {
    setSearchValue(value)
  }, 500)

  const handleOnChangeRowsPerPage = rowsPerPage => {
    setFilters(filters => ({ ...filters, limit: rowsPerPage }))
  }

  const handleOnChangePage = page => {
    setFilters(filters => ({ ...filters, page: page - 1 }))
  }

  const handleOnSort = (column, sort) => {
    setFilters(filters => ({ ...filters, sort: `${column.selector}_${sort}` }))
  }

  const handleOnGenerateHOCTransactionReport = async thisWeek => {
    try {
      const response = await fetch(
        `${Api.domain}/transactions/hoc/${
          thisWeek ? 'this-week' : 'last-week'
        }`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        }
      )

      try {
        const jsonResponse = await response.body()
        if (!jsonResponse.success) throw new Error(jsonResponse.message)
      } catch {}

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${thisWeek ? 'this-week' : 'last-week'}.csv`
      document.body.appendChild(a)

      a.click()
      a.remove()
    } catch (error) {}
  }

  const hanldeOnExportClick = async () => {
    try {
      const response = await fetch(`${Api.domain}/transactions/export`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      try {
        const jsonResponse = await response.body()
        if (!jsonResponse.success) throw new Error(jsonResponse.message)
      } catch {}
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'transactions.csv'
      document.body.appendChild(a)

      a.click()
      a.remove()
    } catch (error) {
      console.error(error)
    }
  }

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const { page, limit, sort } = filters

      const response = await Api.getTransactions({
        page,
        limit,
        sort,
        search: searchValue,
      })

      if (!response.success) throw new Error(response.message)
      const { data, total_items } = response

      setTransactions(data)
      setTotalItems(total_items)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }, [filters, searchValue])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleOnRowClick = row => {
    if (!row.order_id) return
    navigate(`/admin/transactions/${row.order_id}`)
  }

  return (
    <>
      <div className="transactions">
        <Sidepanel />
        <div className="transactions-content">
          <div className="datatable-wrapper">
            <div className="datatable-header">
              <div className="datatable-header-label-wrapper">
                <span className="datatable-header-label">All Transactions</span>
                <span className="datatable-header-description">
                  An overview of all transactions within the Servbees community
                </span>
              </div>

              <div className="datatables-actions-wrapper">
                <input
                  type="search"
                  onChange={e => handleOnSearchChange(e.target.value)}
                  placeholder="Order ID or Full Name"
                />

                <button
                  className="export-button"
                  type="button"
                  onClick={hanldeOnExportClick}>
                  <Icons.Download className="export-icon" />
                  <span className="export-label">Download .CSV</span>
                </button>

                <button
                  className="export-button"
                  type="button"
                  onClick={() => handleOnGenerateHOCTransactionReport(true)}>
                  <Icons.Download className="export-icon" />
                  <span className="export-label">
                    Generate HOC Report This Week
                  </span>
                </button>
                <button
                  className="export-button"
                  type="button"
                  onClick={() => handleOnGenerateHOCTransactionReport(false)}>
                  <Icons.Download className="export-icon" />
                  <span className="export-label">
                    Generate HOC Report Last Week
                  </span>
                </button>
              </div>
            </div>
            <DataTable
              data={transactions}
              columns={columns}
              noHeader={true}
              pagination={true}
              paginationTotalRows={totalItems}
              paginationServer={true}
              sortServer={true}
              onSort={handleOnSort}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onChangeRowsPerPage={handleOnChangeRowsPerPage}
              onChangePage={handleOnChangePage}
              responsive={true}
              fixedHeader={true}
              progressPending={isLoading}
              defaultSortField={'date'}
              defaultSortAsc={false}
              onRowClicked={handleOnRowClick}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Transactions
