import React, { useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import SidePanel from '../../layouts/Sidepanel'
import '../../styles/pages/admin/settings.scss'

const FeatureControl = ({ country }) => {
  return (
    <div className="feature-control-content">
      <h2>Settings - {country} </h2>
    </div>
  )
}

const SetupSettings = () => {
  const [selectedCountry, setSelectedCountry] = useState('philippines')

  return (
    <div className="dashboard">
      <div className="panel-wrapper">
        <SidePanel />
        <div className="container">
          <div className="container-wrapper">
            <div className="country">
              <div>
                <button className="btn btn-light">Add Country</button>
              </div>
            </div>
            <div className="control-container">
              <div className="settings-nav">
                <div className="nav-section">
                  <ul>
                    <li
                      className={
                        selectedCountry === 'philippines' ? 'active' : ''
                      }
                      onClick={() => setSelectedCountry('philippines')}>
                      <a>Philippines</a>
                    </li>
                    <li
                      className={
                        selectedCountry === 'singapore' ? 'active' : ''
                      }
                      onClick={() => setSelectedCountry('singapore')}>
                      <a>Singapore</a>
                    </li>
                    <li
                      className={selectedCountry === 'malaysia' ? 'active' : ''}
                      onClick={() => setSelectedCountry('malaysia')}>
                      <a>Malaysia</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="settings-content">
                <FeatureControl country={selectedCountry} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupSettings
